<template>
  <div class="loan_item">
    <div class="inner inner1">
      <div class="left_cell">
        <img src="@/assets/images/loanImg/contatc_us.png" alt="">
      </div>
      <div class="right_cell">
        <!-- <img src="@/assets/images/loanImg/bn_e.png" alt=""> -->
        <div class="title">
          InstantLoan is an online finance application whereusers can apply for personal loans up to <span>₱ 30,000 </span> anytime and anywhere
        </div>
        <div class="item">
          Financing limit between ₱.1000-₱.30,000.
        </div>
        <div class="item">
          Repayment tenure(s) under 180 days.
        </div>
        <div class="item">
          Daily markup rate 0.067%-0.9%, withone time processing fee.
        </div>
        <div class="item">
          Penalty will be charged after due date.
        </div>
      </div>
    </div>
    <div class="inner inner5">
      <div class="inner5-bg">
        <h3>Eligibility Criteria</h3>
      </div>
      <div class="inner5_item">
        <div class="content">
          <div class="left_item">
            <img src="@/assets/images/loanImg/contact_inner2-1.png" alt="">
            <div class="item_info">
              <h2>Age 18-60 years</h2>
              <!-- <p>Respect and tolerance, empathy</p> -->
            </div>
          </div>
        </div>
        <div class="content">
          <div class="left_item">
            <img src="@/assets/images/loanImg/contact_inner2-2.png" alt="">
            <div class="item_info">
              <h2>Philippines citizen</h2>
              <!-- <p>Collaborate with each other and continue to evolve</p> -->
            </div>
          </div>
        </div>
        <div class="content">
          <div class="left_item">
            <img src="@/assets/images/loanImg/contact_inner2-3.png" alt="">
            <div class="item_info">
              <h2>Monthly income</h2>
              <!-- <p>Beyond innovation, explore the future</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner2">
      <!-- <h4>Sobre nosotros</h4> -->
      <div class="content">
        <div class="left_cell">
          <h1>Information Disclosure</h1>
          <p>Company name: Dolphin Lending Investor,Inc.</p>
          <p>SEC Registration No.CS201911897</p>
          <p>CERTIFICATE OF AUTHORITY NO.3037</p>
        </div>
        <div class="right_cell">
          <img src="@/assets/images/loanImg/contact_inner2.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.loan_item{
  margin-top: 150px;
  // padding-bottom: 60px;
  .inner{
    // min-width: 1100px;
    overflow: hidden;
    h3{
      font-size: 18px;
      color: #6E60F9;
    }
    .content{
      padding: 0px calc((100% - 1100px) / 2) 0;
      display: flex;
    }
    overflow: hidden;
    h3{
      font-size: 36px;
      color: #333333;
    }
    .left_cell,.right_cell{
      flex: 1;
    }
    .left_cell{
      .p1{
        font-size: 18px;
        color: #333333;
        margin-bottom: 10px;
      }
      .p2{
        font-size: 16px;
        color: #999999;
        margin-bottom: 40px;
        line-height: 24px;
      }
    }
    .right_cell{
      img{
        width: 522px;
      }
    }
  }
  .inner1{
    display: flex;
    align-items: center;
    margin: 0 30px;
    .left_cell{
      img{
        width: 100%;
        height: auto;
      }
    }
    .right_cell{
      margin: 0 calc(1100px / 7);
      border-radius: 20px;
      .title{
        padding: 20px;
        background: #6E60F9;
        color: white;
        border-radius: 20px 20px 0 0 ;
        line-height: 20px;
      }
      .item{
        padding: 20px;
        background: #F8F9FF;
      }
      .item:nth-last-child(1){
        border-radius:  0 0 20px 20px;
      }
    }
  }
  .inner2{
    padding-bottom: 50px;
    .content{
      display: flex;
      .left_cell,.right_cell{
        flex: 1;
        display: flex;
        justify-content: center;
      }
      img{
        margin: 25px auto;
        width: 360px;
        height: 200px;
        // display: block;
      }
      h1{
        font-size: 40px;
      }
      .left_cell{
        display: flex;
        flex-direction: column;
        p{
          font-size: 12px;
          line-height: 45px;
          color: black;
        }
      }
    }
  }
  .inner5{
    margin-top: 100px;
    padding: 0 0 70px 0;
    .inner5-bg{
      width: 100%;
      height: 250px;
      background: linear-gradient(271deg, #9D409B 0%, #160033 100%);
      // padding: 50px 250px;
    }
    h2{
      line-height: 30px;
      margin-bottom: 20px;
    }
    h3{
      font-size: 40px;
      color: white;
      text-align: center;
      line-height:150px;
    }
    .inner5_item{
      // width: 100%;
      display: flex;
      justify-content: space-between;
      margin: -100px 0 0;
      padding: 0px calc((100% - 1100px) / 2) 0;
      font-size: 14px;
      text-align: center;
      line-height: 16px;
      .content{
        flex: 1;
        background: #fff;
        padding: 40px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 25px;
        box-shadow: 0 0 5px #999;
        img{
          // margin-top: 50px;
          width: 100px;
        }
      }
    }
  }
}
@media only screen and (max-width: 800px){
  .loan_item{
    margin-top: 70px;
    padding-bottom: 00px;
    // .inner{
    //   min-width: calc(100vw) !important;
    // }
    .inner1{
      flex-direction: column !important;
      justify-content: center;
      margin: 0  30px 50px !important;
      .left_cell{
        img{
          margin-top: 0px;
          width: 300px !important;
          height: 250px !important;
          margin: 0 auto ;
        }
      }
      .right_cell{
        margin-top: 50px;
        width: 300px !important;
      }
    }
    .inner5{
      padding: 0 0 100px 0  !important;
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      h3{
        font-size: 18px;
      }
      .inner5_item{
        margin:  -100px 0px !important;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .content{
          margin: 20px 25px !important;
        }
      }
    }
    .inner2{
      h4{
        padding: 0 20px;
      }
      h1{
        margin: 20px 0;
      }
      padding-bottom: 0;
      .content{
        padding: 0 20px !important;
        display: flex;
        flex-direction: column;
        .left_cell{
          p{
            padding: 0 !important;
            width: 100% !important;
            margin:  15px 0;
            line-height: 15px;
          }
        }
        img{
          margin: 20px 0px 50px;
        }
      }
    }
  }
}
</style>
